






























import Vue from 'vue';
import { Person, Attitude } from '@/interfaces/Person';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DataViewBase from '@/classes/component-bases/DataViewBase';

@Component
export default class CardView extends DataViewBase {
  @Prop()
  private persons!: Person[];

  @Prop()
  private attitudeitems!: Attitude[];

  @Prop()
  private search!: string;

  @Prop()
  private singleColumn!: boolean;

  private sorting: string = 'name';

  private page = 1;
  private pageSize = 30;

  constructor() {
    super();
  }

  get displayData() {
    // Lastly apply pagination
    const start = (this.page - 1) * this.pageSize;
    return this.dataSorted.slice(start, start + this.pageSize);
  }

  get totalPages() {
    if (typeof this.persons === 'undefined' || !Array.isArray(this.persons)) {
      return 1;
    }
    return Math.ceil(this.persons.length / this.pageSize);
  }

  get dataSorted() {
    if (typeof this.persons === 'undefined' || !Array.isArray(this.persons)) {
      return [];
    }
    return this.persons.sort((a, b) => {
      if (
        (typeof a.loadedImageUrl === 'undefined' || !a.loadedImageUrl) &&
        typeof b.loadedImageUrl !== 'undefined' &&
        b.loadedImageUrl !== null
      ) {
        return 1;
      } else if (
        typeof a.loadedImageUrl !== 'undefined' &&
        a.loadedImageUrl !== null &&
        (typeof b.loadedImageUrl === 'undefined' || !b.loadedImageUrl)
      ) {
        return -1;
      }
      return a[this.sorting] > b[this.sorting] ? 1 : -1;
    });
  }

  private onCardClick(person: Person) {
    this.$emit('focusperson', person);
  }
}
