


































import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { Person } from '@/interfaces/Person';

@Component
export default class SingleView extends Vue {
  private dialogModel = false;

  @Prop()
  private person!: Person;

  @Prop()
  private visible!: boolean;

  @Watch('visible')
  private visibleChange(newValue) {
    this.dialogModel = newValue;
  }

  @Watch('dialogModel')
  private dialogModelChange(newValue) {
    if (newValue === false) {
      this.$emit('closedialog');
    }
  }
}
