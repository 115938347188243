import { render, staticRenderFns } from "./cardview.vue?vue&type=template&id=4eb7c782&scoped=true&"
import script from "./cardview.vue?vue&type=script&lang=ts&"
export * from "./cardview.vue?vue&type=script&lang=ts&"
import style0 from "./cardview.vue?vue&type=style&index=0&id=4eb7c782&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eb7c782",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VContainer,VFlex,VImg,VLayout,VPagination})
