













































































































import Vue from 'vue';
import * as L from 'leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { createHash } from 'crypto';
import { WorldMarker } from '@/interfaces/Marker';
import cardview from '@/components/world/cardview.vue';
import singleview from '@/components/world/single.vue';
import colors from '../../constants/colors';
import { Person } from '@/interfaces/Person';
import { createHook } from 'async_hooks';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mdiMagnify } from '@mdi/js';

Icon.Default.mergeOptions({
  iconUrl: require('../../assets/icons/danger-kyan.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

@Component({
  components: { cardview, singleview },
})
export default class WorldMap extends Vue {
  private map!: L.Map;
  private markers: WorldMarker[] = [];
  private searchIcon = mdiMagnify;
  private availableColors: string[] = colors;
  private availableIcons = [
    { name: 'circle', source: 'circle' },
    { name: 'silver-circle', source: 'image' },
    { name: 'nature', source: 'image' },
    { name: 'danger-kyan', source: 'image' },
    { name: 'danger-red', source: 'image' },
    { name: 'archway', source: 'fa' },
    { name: 'dungeon', source: 'fa' },
    { name: 'gopuram', source: 'fa' },
    { name: 'landmark', source: 'fa' },
    { name: 'monument', source: 'fa' },
    { name: 'torii-gate', source: 'fa' },
    { name: 'vihara', source: 'fa' },
    { name: 'fist-raised', source: 'fa' },
    { name: 'dragon', source: 'fa' },
    { name: 'paw-claws', source: 'fa' },
  ];
  private contentDrawerOpen = false;
  private editDrawerOpen = false;
  private currentSelectedMarker: WorldMarker | null = null;
  private previousMarkerState: WorldMarker | null = null;
  private tempIconModel = 0;
  private tempIcon: WorldMarker = {
    lat: 0,
    lng: 0,
    type: 'circle',
    color: 'red',
    icon: undefined,
    name: '',
  };
  private tempMarker: L.Marker | L.CircleMarker | null = null;
  private personsLoading = true;
  private personsOfMarker: Person[] = [];
  private focusPerson: Person | null = null;
  private singleDialogOpen = false;
  private tempIconZoom = [6, 10];
  private markersOnMap: string[] = [];
  private renderedMarkers: string[] = [];
  private zoomClass = 'zoom--8';

  constructor() {
    super();
  }

  /**
   * Init function for creating the map
   */
  public mounted() {
    // Set up the map and its viewport
    const map = L.map('worldMap', {
      crs: this.crs,
      minZoom: 8,
      maxZoom: 10,
    });
    const southWest = map.unproject([0, 9960], 10);
    const northEast = map.unproject([6537, 0], 10);
    map.setView(map.unproject([3200, 5000], 10), 8);

    // Add tilelayer
    L.tileLayer(this.url, {
      attribution: 'Map data &copy; Vjorngard',
      maxZoom: 10,
      minZoom: 6,
      bounds: L.latLngBounds(southWest, northEast),
    }).addTo(map);

    // Set bounds
    map.setMaxBounds(L.latLngBounds(southWest, northEast));

    // Create markers
    this.$store.dispatch('loadWorldMapMarkers').then(() => {
      this.markers = this.$store.getters.markers;
      this.createMarkers(map);
    });

    // Setup the listenen for the marker event
    map.on('contextmenu', this.handleContextMenu);
    map.on('zoomend', () => {
      this.zoomClass = 'zoom--' + map.getZoom();
    });
    this.map = map;
  }

  /**
   * Handles right clicking anywhere on the map
   */
  private handleContextMenu(event: any) {
    if (this.map === null || this.contentDrawerOpen) {
      return;
    }
    this.editDrawerOpen = false;
    this.contentDrawerOpen = false;
    this.tempIcon.x = event.containerPoint.x;
    this.tempIcon.y = event.containerPoint.y;
    this.$nextTick(() => {
      this.editDrawerOpen = true;
    });
    if (this.tempMarker === null) {
      const currentZoom = this.map.getZoom();
      this.tempMarker = L.circleMarker(event.latlng, {
        fill: true,
        color: 'red',
        fillOpacity: 1,
        radius: 4,
      }).addTo(this.map);
    } else {
      this.tempMarker.setLatLng(event.latlng);
    }

    // Set to temp icon
    this.tempIcon.lat = event.latlng.lat;
    this.tempIcon.lng = event.latlng.lng;
  }

  /**
   * Creates the markers on the maps and assigns their eventhandlers
   */
  private createMarkers(map: L.Map) {
    this.markers.forEach((marker) => {
      let createdMarker: L.Marker | L.CircleMarker;
      if (marker.id === undefined) {
        throw new Error('Marker id is undefined');
      }

      // If marker was already rendered, skip it
      if (this.renderedMarkers.includes(marker.id)) {
        return;
      } else {
        this.renderedMarkers.push(marker.id);
      }

      // Circle markers
      if (marker.type === 'circle') {
        const color = typeof marker.color !== 'undefined' ? marker.color : '#ff0000';
        createdMarker = L.circleMarker(new L.LatLng(marker.lat, marker.lng), {
          fill: true,
          color,
          fillOpacity: 1,
          radius: 4,
        });

        // Icon markers
      } else if (marker.type === 'image' && marker.icon) {
        createdMarker = L.marker(new L.LatLng(marker.lat, marker.lng), {
          icon: this.getMarkerIcon('image', marker.icon, marker.color, map.getZoom()),
        });
      } else if (marker.type === 'icon' && marker.icon) {
        createdMarker = L.marker(new L.LatLng(marker.lat, marker.lng), {
          icon: this.getMarkerIcon('icon', marker.icon, marker.color, map.getZoom()),
        });
      } else {
        throw new Error('Unsupported marker type: ' + marker.type);
      }

      if (this.shouldMarkerBeShown(map, marker)) {
        createdMarker.addTo(map);
        this.markersOnMap.push(marker.id);
      }

      createdMarker.bindTooltip(marker.name);

      // Handle clicking on markers
      createdMarker.on('click', (event: any) => {
        this.contentDrawerOpen = true;
        this.currentSelectedMarker = marker;
        this.tempMarker = event.target;
        const targetZoom = map.getZoom() > 9 ? map.getZoom() : 9;
        map.setView(event.latlng, targetZoom, { duration: 1 });
      });

      // size adjustments of markers
      map.on('zoomend', () => {
        const currentZoom = map.getZoom();
        if (createdMarker instanceof L.CircleMarker) {
          if (currentZoom === 6) {
            createdMarker.setRadius(1);
          } else {
            createdMarker.setRadius(currentZoom * 0.33);
          }

          // For icon markers, resize the icon
        } else if (marker.type === 'image' && marker.icon) {
          const icon = this.getMarkerIcon('image', marker.icon, marker.color, currentZoom);
          createdMarker.setIcon(icon);
        } else if (marker.type === 'icon' && marker.icon) {
          const icon = this.getMarkerIcon('icon', marker.icon, marker.color, currentZoom);
          createdMarker.setIcon(icon);
        } else {
          throw new Error('Unsupported marker on zoom: ' + marker.type);
        }

        if (marker.id === undefined) {
          return;
        }
        const index = this.markersOnMap.indexOf(marker.id);

        // if the marker shouldn't be shown and is currently on the map, remove it
        if (!this.shouldMarkerBeShown(map, marker) && index > -1) {
          createdMarker.removeFrom(map);
          this.markersOnMap.splice(index, 1);

          // if the marker should be shown and is currently NOT on the map, add it
        } else if (this.shouldMarkerBeShown(map, marker) && index === -1) {
          createdMarker.addTo(map);
          this.markersOnMap.push(marker.id);
        }
      });
    });
  }

  /**
   * Takes the currently selected marker and edits it
   */
  private editMarker() {
    if (this.currentSelectedMarker === null) {
      return;
    }
    // Save the state of this marker so that we can reset it
    this.previousMarkerState = Object.assign({}, this.currentSelectedMarker);

    // Set tempIconModel
    this.tempIconModel = this.availableIcons.findIndex((icon) => {
      if (this.currentSelectedMarker === null) {
        throw new Error('CurrentSelectedMarker is null in editMarker');
      }
      return icon.name === this.currentSelectedMarker.icon;
    });

    // Assign marker to edit marker
    this.tempIcon = this.currentSelectedMarker;

    // Close content drawer and open edit drawer
    this.contentDrawerOpen = false;
    this.editDrawerOpen = true;
  }

  /**
   * Closes edit drawer and resets new icon.
   */
  private cancleEdit() {
    this.editDrawerOpen = false;

    // Only remove the marker if it is a newly created one
    if (!this.tempIcon.id && this.tempMarker) {
      this.tempMarker.removeFrom(this.map);
      this.tempMarker = null;
    }
    this.tempIcon = {
      name: '',
      lat: 0,
      lng: 0,
      color: 'red',
      icon: 'circle',
      type: 'circle',
    };

    // In case we have a previous state, reset it
    if (this.previousMarkerState && this.tempMarker) {
      const previousLatLng = new L.LatLng(this.previousMarkerState.lat, this.previousMarkerState.lng);
      this.tempMarker.setLatLng(previousLatLng);
      if (this.previousMarkerState.icon && this.tempMarker instanceof L.Marker) {
        this.tempMarker.setIcon(
          this.getMarkerIcon(
            this.previousMarkerState.type,
            this.previousMarkerState.icon,
            this.previousMarkerState.color,
            this.map.getZoom(),
          ),
        );
      }
    }
    this.previousMarkerState = null;
  }

  /**
   * Saves the current temporary marker
   */
  private saveMarker() {
    const markerTransformed = Object.assign({}, this.tempIcon);
    markerTransformed.icon = this.tempIconIcon;
    this.$store
      .dispatch('addMarker', markerTransformed)
      .then((newMarkerId) => {
        this.editDrawerOpen = false;
        markerTransformed.id = newMarkerId;

        // Reset the temp Icon on success
        this.tempIcon = {
          lat: 0,
          lng: 0,
          type: 'circle',
          icon: 'circle',
          name: '',
          color: 'red',
        };
        this.tempIconModel = 0;
        this.tempIconZoom = [6, 10];

        // Reset previous state
        this.previousMarkerState = null;

        // Remove temp Marker
        if (this.tempMarker !== null) {
          this.tempMarker.remove();
          this.tempMarker = null;
        }

        // Add marker to already existing
        this.markers.push(markerTransformed);
        // If this marker was edited, remove it from list of rendered
        // markers, as we want to re-render it
        this.renderedMarkers = this.renderedMarkers.filter((markerId) => markerId !== newMarkerId);
        this.createMarkers(this.map);
      })
      .catch((reason) => {
        throw new Error(reason);
      });
  }

  get crs() {
    return L.CRS.Simple;
  }

  get url() {
    const token = '7bb4f00e-4605-4397-85e4-145bd3fc70e4';
    const host = 'https://firebasestorage.googleapis.com/v0/b/pnp-utilities.appspot.com';
    return host + '/o/map%2Fpathfinder-2e%2Fzoom{z}%2Fmap_{x}_{y}.jpg?alt=media&token=' + token;
  }

  @Watch('tempIconIcon')
  private changeTempMarkerIcon() {
    if (this.tempMarker !== null) {
      const currentZoom = this.map.getZoom();
      const source = this.availableIcons[this.tempIconModel].source;
      let icon: L.Icon | L.DivIcon;
      this.switchTempMarkerIfNecessary(source);
      if (source === 'fa') {
        icon = this.getMarkerIcon('icon', this.tempIconIcon, this.tempIcon.color, currentZoom);
        this.tempIcon.type = 'icon';
      } else if (source === 'image') {
        icon = this.getMarkerIcon('image', this.tempIconIcon, this.tempIcon.color, currentZoom);
        this.tempIcon.type = 'image';
      } else if (source === 'circle') {
        return;
      } else {
        throw new Error('Unsupported tempIconIcon: ' + this.availableIcons[this.tempIconModel].source);
      }

      if (this.tempMarker instanceof L.Marker) {
        this.tempMarker.setIcon(icon);
      }
    }
  }

  @Watch('tempIconColor')
  private changeTempIconColor() {
    if (this.tempMarker !== null && (this.tempIcon.type === 'icon' || this.tempIcon.type === 'circle')) {
      const zoom = this.map.getZoom();
      if (this.tempMarker instanceof L.Marker) {
        this.tempMarker.setIcon(this.getMarkerIcon('icon', this.tempIconIcon, this.tempIcon.color, zoom));
      } else if (this.tempMarker instanceof L.CircleMarker) {
        const style = { color: this.tempIcon.color, fill: true };
        this.tempMarker.setStyle(style);
      } else {
        throw new Error('tempMarker is neither Marker nor CircleMarker in watch(tempIconColor)');
      }
    }
  }

  @Watch('currentSelectedMarker')
  private currentSelectedMarkerChange(newMarker: WorldMarker | null) {
    if (!newMarker) {
      return;
    }
    this.personsLoading = true;

    this.$store.dispatch('getPersonsByLocation', newMarker.id).then((foundPersons: Person[]) => {
      this.personsLoading = false;
      this.personsOfMarker = foundPersons;
    });
  }

  @Watch('tempIconZoom')
  private onTempIconZoomChange(newZoomLevels: number[]) {
    this.tempIcon.minZoom = newZoomLevels[0];
    this.tempIcon.maxZoom = newZoomLevels[1];
  }

  /**
   * Creates a font awesome icon based on icon name, color and zoom
   */
  private getMarkerIcon(type: string, icon: string, color: string, zoom: number) {
    if (type === 'icon') {
      // Workaround to insert FAIcons programmatically. (Functional component render)
      const wrapperComponent = Vue.extend({
        components: { FontAwesomeIcon },
        template:
          "<font-awesome-icon :icon=\"['fas', '" +
          icon +
          '\']" :style="{ color: \'' +
          color +
          '\'}"></font-awesome-icon>',
      });
      const instance = new wrapperComponent().$mount();
      return L.divIcon({
        html: instance.$el as HTMLElement,
        iconSize: [zoom * 2, zoom * 2],
        className: 'mapIconMarker',
      });
    } else if (type === 'image') {
      return new L.Icon({
        iconUrl: require('../../assets/icons/' + icon + '.png'),
        iconSize: [zoom * 2, zoom * 2],
        tooltipAnchor: [zoom, zoom / 2],
      });
    } else {
      throw new Error('getMarkerIcon unsupported type: ' + type);
    }
  }

  get tempIconIcon() {
    return this.availableIcons[this.tempIconModel].name;
  }

  get tempIconColor() {
    return this.tempIcon.color;
  }

  get markersSortedByName() {
    return JSON.parse(JSON.stringify(this.markers)).sort((markerA, markerB) => (markerA.name > markerB.name ? 1 : -1));
  }

  private onFocusPerson(person: Person) {
    this.focusPerson = person;
    this.singleDialogOpen = true;
  }

  private onSingleDialogClosed() {
    this.singleDialogOpen = false;
  }

  private onSelectLocation(markerId: string) {
    // Fetch the marker by that Id
    const selectedMarker = this.markers.find((marker) => marker.id === markerId);

    if (typeof selectedMarker === 'undefined') {
      throw new Error('Search did not find markerId: ' + markerId);
    }

    this.map.flyTo(new L.LatLng(selectedMarker.lat, selectedMarker.lng), 10, { duration: 2, animate: true });
  }

  private shouldMarkerBeShown(map: L.Map, marker: WorldMarker): boolean {
    const zoom = map.getZoom();
    if (typeof marker.minZoom !== 'undefined' && map.getZoom() < marker.minZoom) {
      return false;
    } else if (typeof marker.maxZoom !== 'undefined' && map.getZoom() > marker.maxZoom) {
      return false;
    }
    return true;
  }

  private switchTempMarkerIfNecessary(target: string) {
    if (this.tempMarker === null) {
      throw new Error('Cannot switch tempMarker, because it is null');
    }

    if (target === 'circle' && this.tempMarker instanceof L.Marker) {
      this.switchTempMarkerToCircle();
    } else if ((target === 'fa' || target === 'image') && this.tempMarker instanceof L.CircleMarker) {
      this.switchTempMarkerToIcon();
    }
  }

  private switchTempMarkerToCircle() {
    if (this.tempMarker === null) {
      throw new Error('TempMarker is null');
    }
    if (this.tempMarker instanceof L.CircleMarker) {
      throw new Error('TempMarker is already Circle');
    }

    this.tempMarker.remove();

    this.tempMarker = L.circleMarker(this.tempMarker.getLatLng(), {
      fill: true,
      color: this.tempIconColor,
      fillOpacity: 1,
      radius: 4,
    }).addTo(this.map);
  }

  private switchTempMarkerToIcon() {
    if (this.tempMarker === null) {
      throw new Error('TempMarker is null');
    }
    if (this.tempMarker instanceof L.Marker) {
      throw new Error('TempMarker is already IconMarker');
    }

    this.tempMarker.remove();
    this.tempMarker = L.marker(this.tempMarker.getLatLng(), {
      icon: this.getMarkerIcon('image', 'silver-circle', '', this.map.getZoom()),
    }).addTo(this.map);
  }
}
