import Vue from 'vue';
import { Person, Attitude } from '@/interfaces/Person';

export default class DataViewBase extends Vue {

  public static importanceString(importance): string {
    switch (importance) {
      case 5:
        return 'Sehr wichtig';
      case 4:
        return 'Wichtig';
      case 3:
        return 'Mittelwichtig';
      case 2:
        return 'Unwichtig';
      case 1:
        return 'Sehr unwichtig';
      default:
        return '';
    }
  }

  protected getChipColor(attitude) {
    switch (attitude) {
      case 'evil':
        return '#cc3333';
      case 'neutral':
        return 'grey';
      case 'good':
        return '#33cc33';
      case 'unknown':
        return 'purple';

      default:
        return 'silver';
    }
  }

  protected getAttitudeTranslated(value, attitudeitems: Attitude[]) {
    let translatedValue = value;
    attitudeitems.forEach((item) => {
      if (item.value === value) {
        translatedValue = item.text;
      }
    });

    return translatedValue;
  }

  protected getStatusOfPerson(person: Person): string[] {
    if (!person.hasOwnProperty('statusModel') || !Array.isArray(person.statusModel)) {
      return [];
    }
    const statusTransformed: string[] = [];
    if (person.statusModel.length > 0) {
      statusTransformed.push('block');
    }

    return statusTransformed;
  }

  protected getEncounterHTMLString(encounters: string[] | undefined) {
    if (typeof encounters === 'undefined' || encounters.length === 0) {
      return '';
    }

    const encounterMap: object = {};
    encounters.forEach((encounter) => {
      const encounterParts = encounter.split('-');
      if (encounterParts.length !== 2) {
        return;
      }
      if (!encounterMap.hasOwnProperty(encounterParts[0])) {
        encounterMap[encounterParts[0]] = [];
      }

      encounterMap[encounterParts[0]].push(encounterParts[1]);
    });

    let htmlString = '';
    for (const year in encounterMap) {
      if (typeof encounterMap[year] === 'undefined') {
        continue;
      }
      htmlString = '<div style="display: inline">' + year + ': </div> <div style="display: inline">';
      const months = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
      ];
      months.forEach((month) => {
        if (encounterMap.hasOwnProperty(year) && encounterMap[year].indexOf(month) !== -1) {
          htmlString += '<span class="encountered">' + month + '</span> ';
        }
      });
    }

    return htmlString + '</div>';
  }

  protected editItem(item) {
    this.$emit('edititem', item);
  }

  protected confirmDeletion(item) {
    this.$emit('confirmdeletion', item);
  }
}
